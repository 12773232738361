import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import GlobalTokens from '@sainsburys-tech/tokens/dist/global/js/json/color/index.json';
import ColourBlock from '@components/colour-block';
import FurtherReading from '@shared/colours/further-reading';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-palettes"
            }}>{`Colour palettes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#colour-accessibility"
            }}>{`Colour accessibility`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "colour-palettes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-palettes",
        "aria-label": "colour palettes permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour palettes`}</h2>
    <p>{`Argos’ selection of colours are divided into distinct palettes. The available palettes are Primary and Monochrome.`}</p>
    <h3>{`Primary palette`}</h3>
    <p>{`Argos’ core colours are red, green, blue, and yellow. The red and green are the colours associated with Argos by users, so it’s important to use this distinctive colour first and foremost.`}</p>
    <h4>{`Primary base colours`}</h4>
    <GridList columns="2" mdxType="GridList">
  <ColourBlock fill="#D42114" mdxType="ColourBlock">Red Base</ColourBlock>
  <ColourBlock fill="#008542" mdxType="ColourBlock">Green Base</ColourBlock>
  <ColourBlock fill="#0C509F" mdxType="ColourBlock">Blue Base</ColourBlock>
  <ColourBlock fill="#EFC71D" mdxType="ColourBlock">Yellow Base</ColourBlock>
    </GridList>
    <h4>{`Primary extended colours`}</h4>
    <h5>{`Red extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#7F150D" mdxType="ColourBlock">Red Dark</ColourBlock>
  <ColourBlock fill="#FFEEED" mdxType="ColourBlock">Red Light</ColourBlock>
    </GridList>
    <h5>{`Green extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#045228" mdxType="ColourBlock">Green Dark</ColourBlock>
  <ColourBlock fill="#EDFFF6" mdxType="ColourBlock">Green Light</ColourBlock>
    </GridList>
    <h5>{`Blue extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#08305F" mdxType="ColourBlock">Blue Dark</ColourBlock>
  <ColourBlock fill="#E4F1FF" mdxType="ColourBlock">Blue Light</ColourBlock>
    </GridList>
    <h5>{`Yellow extended colours`}</h5>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#BF9F18" mdxType="ColourBlock">Yellow Dark</ColourBlock>
    </GridList>
    <h3>{`Monochrome palette`}</h3>
    <p>{`The monochome palette is a group of colours that can provide text contrast and neutrality to designs.`}</p>
    <GridList columns="4" mdxType="GridList">
  <ColourBlock fill="#000000" mdxType="ColourBlock">Monochrome Black</ColourBlock>
  <ColourBlock fill="#262626" mdxType="ColourBlock">Monochrome Darker</ColourBlock>
  <ColourBlock fill="#404040" mdxType="ColourBlock">Monochrome Dark</ColourBlock>
  <ColourBlock fill="#737373" mdxType="ColourBlock">Monochrome Medium</ColourBlock>
  <ColourBlock fill="#D8D8D8" mdxType="ColourBlock">Monochrome Light</ColourBlock>
  <ColourBlock fill="#F2F2F2" mdxType="ColourBlock">Monochrome Lighter</ColourBlock>
  <ColourBlock fill="#FFFFFF" mdxType="ColourBlock">Monochrome White</ColourBlock>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "colour-accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#colour-accessibility",
        "aria-label": "colour accessibility permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Colour accessibility`}</h2>
    <p>{`To comply with the Web Content Accessibility Guidelines AA standard contrast ratios, choose colours from the Argos palette that have sufficient color contrast between them so that people with low vision can see and use our products.`}</p>
    <h3>{`Primary palette accessibility`}</h3>
    <p>{`When using Red Base (#D42114), Green Base (#008542) or Blue Base (#0C509F) as a background colour, only use Monochrome White (#FFFFFF) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#D42114" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.white}`
          }}>
        Monochrome White
      </span>
    </ColourBlock>
    <ColourBlock fill="#008542" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.white}`
          }}>
        Monochrome White
      </span>
    </ColourBlock>
    <ColourBlock fill="#0C509F" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.white}`
          }}>
        Monochrome White
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#D42114" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.light}`
          }}>
        Monochrome Light
      </span>
    </ColourBlock>
    <ColourBlock fill="#008542" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.light}`
          }}>
        Monochrome Light
      </span>
    </ColourBlock>
    <ColourBlock fill="#0C509F" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.light}`
          }}>
        Monochrome Light
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Yellow Base (#EFC71D) as a background colour, only use Monochrome Black (#000000), Monochrome Darker (#262626) or Monochrome Dark (#404040) for text.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#EFC71D" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.dark}`
          }}>
        Monochrome Dark
      </span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#EFC71D" center={true} mdxType="ColourBlock">
      <span style={{
            color: `${GlobalTokens.color.monochrome.medium}`
          }}>
        Monochrome Medium
      </span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Red Base (#D42114), Green Base (#008542) or Blue Base (#0C509F) as a text colour on a monochrome background, only use Monochrome White (#FFFFFF) or Monochrome Lighter (#F2F2F2) as a background colour.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#F2F2F2" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#D42114'
          }}>Red Base</span>
    </ColourBlock>
    <ColourBlock fill="#F2F2F2" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#008542'
          }}>Green Base</span>
    </ColourBlock>
    <ColourBlock fill="#F2F2F2" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#0C509F'
          }}>Blue Base</span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#D8D8D8" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#D42114'
          }}>Red Base</span>
    </ColourBlock>
    <ColourBlock fill="#D8D8D8" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#008542'
          }}>Green Base</span>
    </ColourBlock>
    <ColourBlock fill="#D8D8D8" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#0C509F'
          }}>Blue Base</span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <p>{`When using Red Base (#D42114), Green Base (#008542) or Blue Base (#0C509F) as a text colour on a coloured background, use the Light extended colour of the base colour for the background colour.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <p>Minimal contrast ratio: 4.5</p>
    <ColourBlock fill="#FFEEED" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#D42114'
          }}>Red Base</span>
    </ColourBlock>
    <ColourBlock fill="#EDFFF6" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#008542'
          }}>Green Base</span>
    </ColourBlock>
    <ColourBlock fill="#E4F1FF" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#0C509F'
          }}>Blue Base</span>
    </ColourBlock>
  </Do>
  <Dont mdxType="Dont">
    <p>Fail</p>
    <ColourBlock fill="#7F150D" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#D42114'
          }}>Red Base</span>
    </ColourBlock>
    <ColourBlock fill="#045228" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#008542'
          }}>Green Base</span>
    </ColourBlock>
    <ColourBlock fill="#08305F" center={true} mdxType="ColourBlock">
      <span style={{
            color: '#0C509F'
          }}>Blue Base</span>
    </ColourBlock>
  </Dont>
    </Guideline>
    <hr></hr>
    <FurtherReading mdxType="FurtherReading" />
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      